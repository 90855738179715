<template>
    <div fluid class="m-t-10">
        <!-- <Loader v-if="loading" /> -->

        <b-card class="body-card" no-body>
            <b-row>
                <b-col cols="6">
                    <b-form-select @change="changeSelectedValue" size="lg" v-model="selected" :options="options">
                    </b-form-select>
                </b-col>
                <b-col v-if="showHieraricalMode" cols="5">
                    <b-form-select @change="selectLifeCycleStage" size="lg" v-model="hieraricalData.lifeCycleStage"
                        :options="lifeCycleOptions">
                    </b-form-select>
                </b-col>
                <b-col id="addHieraricalData" v-if="selected == 'lifeCycle'" cols="1">
                    <button @click="setHieraricalMode" class="mb-0 btn h1">
                        <b-icon icon="plus-circle" variant="dark"></b-icon>
                    </button>
                    <b-tooltip :trigger="'hover'" target="addHieraricalData" placement="top" variant="info">
                        Select LC Stage
                    </b-tooltip>
                </b-col>
            </b-row>
            <b-row v-if="hieraricalData.lifeCycleStage !== null" class="mt-30">
                <b-col cols="6">
                    <h3 class="hierarical-data-label">Emission Category</h3>
                </b-col>
                <b-col cols="5">
                    <b-form-select @change="selectEmissionCategory" size="lg" v-model="hieraricalData.emissionCategory"
                        :options="hieraricalEmissionCategoryOptions">
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row v-if="hieraricalData.emissionCategory !== null" class="mt-30">
                <b-col cols="6">
                    <h3 class="hierarical-data-label">Activity Group</h3>
                </b-col>
                <b-col cols="5">
                    <b-form-select @change="selectActivityGroup" size="lg" v-model="hieraricalData.activityGroup"
                        :options="hieraricalactivityGroupsOptions">
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row v-if="hieraricalData.activityGroup !== null" class="mt-30">
                <b-col cols="6">
                    <h3 class="hierarical-data-label">Activity Sub Group</h3>
                </b-col>
                <b-col cols="5">
                    <b-form-select @change="selectActivitySubGroup" size="lg" v-model="hieraricalData.activitySubGroup"
                        :options="hieraricalactivitySubGroupsOptions">
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row v-if="hieraricalData.activitySubGroup !== null" class="mt-30">
                <b-col cols="6">
                    <h3 class="hierarical-data-label">Activity Type</h3>
                </b-col>
                <b-col cols="5">
                    <b-form-select @change="selectActivityType" size="lg" v-model="hieraricalData.activityType"
                        :options="hieraricalactivityTypeOptions">
                    </b-form-select>
                </b-col>
            </b-row>
            <div class="table-wrapper">
                <b-table @sort-changed="handleSortChange" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :items="items" :fields="fields" stacked="sm" show-empty small bordered hover no-border-collapse
                    :busy="loading">
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle mr-3"></b-spinner>
                            <strong>Loading Entries...</strong>
                        </div>
                    </template>
                    <template #cell(Emissions)="data">
                        {{ transformResult(data.value).toLocaleString('en-US') }}
                    </template>
                </b-table>
                <div class="text-right mt-2">
                    <strong>Total:</strong> {{ getTotalEmissions() }}
                </div>
            </div>

            <b-tabs nav-class="tabs-nav" active-nav-item-class="tabs-active" v-if="items.length > 0" pills card>
                <b-tab title="Bar Graph" active>
                    <div class="download-btn-wrapper">
                        <button id="downLoadBarChart" @click="downloadChart('bar-chart')" class="mb-0 btn h1">
                            <b-icon icon="download" variant="dark"></b-icon>
                            <b-tooltip :trigger="'hover'" target="downLoadBarChart" placement="bottom" variant="info">
                                Download Bar Chart
                            </b-tooltip>
                        </button>
                    </div>
                    <div>
                        <BarChart :chartOptions="barChartOptions" :cssClasses="barChartWrapper"
                            :chartData="barChartData" />
                    </div>
                </b-tab>
                <b-tab title="Pie Graph">
                    <div class="download-btn-wrapper">
                        <button id="downLoadPieChart" @click="downloadChart('pie-chart')" class="mb-0 btn h1">
                            <b-icon icon="download" variant="dark"></b-icon>
                            <b-tooltip :trigger="'hover'" target="downLoadPieChart" placement="bottom" variant="info">
                                Download Pie Chart
                            </b-tooltip>
                        </button>
                    </div>
                    <div>
                        <PieChart :chartOptions="pieChartOptions" :cssClasses="pieChartWrapper"
                            :chartData="pieChartData" />
                    </div>
                </b-tab>
            </b-tabs>

            <router-link to="projects">
                <button id="homeButton" class="mb-0 btn h1 float float-1">
                    <img class="home" src="../assets/img/home.svg" />
                </button>
                <b-tooltip :trigger="'hover'" target="homeButton" placement="bottom" variant="info">
                    Home
                </b-tooltip>
            </router-link>

            <button id="backButton" class="mb-0 btn h1 float float-2" @click="backToProject()">
                <b-icon icon="arrow-left-circle" variant="dark" viewBox="4 4 8 8"></b-icon>
            </button>
            <b-tooltip :trigger="'hover'" target="backButton" placement="bottom" variant="info">
                LCA Table
            </b-tooltip>

        </b-card>
    </div>
</template>

<script>
// import axios from "../utility/axios";
import Swal from "sweetalert2";
import { orderBy, find } from "lodash";
import BarChart from "../components/BarGraph.vue"
import PieChart from "../components/PieGraph.vue"

import graphColors from "../data/graphColors.json";

export default {
    components: { BarChart, PieChart },
    data() {
        return {
            loading: false,
            selected: null,
            sortBy: 'Emissions',
            sortDesc: true,
            totalEmissions: 0.0,

            options: [
                { value: null, text: 'Select Classification Type' },
                { value: 'lifeCycle', text: 'Lifecycle Stage' },
                { value: 'emissionCategory', text: 'Emission Category' },
                { value: 'activityGroup', text: 'Activity Group' },
                { value: 'activitySubGroup', text: 'Activity Sub-Group' },
                { value: 'activityType', text: 'Activity Type' },
                { value: 'supplyChainEntity', text: 'Supply Chain Entity' },
                { value: 'product', text: 'Product' },
                { value: 'department', text: 'Department' },
                { value: 'orgBoundaryEntity', text: 'Org. Boundary Entity' },
            ],
            fixedFields: [
                {
                    key: "Emissions",
                    label: "Emissions",
                    class: "text-center",
                    sortable: true
                },
                {
                    key: "units",
                    label: "Units",
                    class: "text-center",
                    sortable: true
                },
                {
                    key: "rank",
                    label: "Rank",
                    class: "text-center",
                    sortable: true
                },
                {
                    key: "contribution",
                    label: "% Contribution",
                    class: "text-center",
                    sortable: true
                },
                {
                    key: "cumalative",
                    label: "Cumulative %",
                    class: "text-center",
                    sortable: false
                },
            ],
            fields: [],
            items: [],
            value: "",
            barChartData: {
                labels: [],
                datasets: [{
                    backgroundColor: [],
                    data: [],
                    borderWidth: 1,
                }]
            },
            barChartOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: '',
                        fullSize: true,
                        font: {
                            size: 24
                        },
                        padding: {
                            top: 10,
                            bottom: 30
                        }
                    }
                }
            },
            pieChartData: {
                labels: [],
                datasets: [{
                    backgroundColor: [],
                    data: [],
                    borderWidth: 1,
                }]
            },
            pieChartOptions: {
                responsive: true,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        fullSize: true,
                        font: {
                            size: 24
                        },
                        padding: {
                            top: 10,
                            bottom: 30
                        }
                    }
                }
            },
            barChartWrapper: "bar-chart-wrapper-sm",
            pieChartWrapper: "pie-chart-wrapper-sm",
            showHieraricalMode: false,
            hieraricalData: {
                lifeCycleStage: null,
                emissionCategory: null,
                activityGroup: null,
                activitySubGroup: null,
                activityType: null
            },
            hieraricalDataOptions: {},
            hieraricalEmissionCategoryOptions: [],
            hieraricalactivityGroupsOptions: [],
            hieraricalactivitySubGroupsOptions: [],
            hieraricalactivityTypeOptions: [],

        };
    },
    methods: {
        backToProject() {
            this.$router.push("/table")
        },
		transformResult(result) {
            if(result == null)
                return 0;

			if (result < 1)
				return parseFloat(result.toFixed(2));
			else if (result <= 100)
				return parseFloat(result.toFixed(1));
			else
				return parseFloat(result.toFixed(0));
		},
        getExploreProjectHighlights(type) {
            this.loading = true;
            let project_id = this.$store.getters.projectId
            this.axios
                .get(
                    `/exploreEmissions?project_id=${project_id}&type=${type}`
                )
                .then((res) => {
                    this.getTableFields(type)
                    this.getTableData(res.data.data)
                    this.loading = false;
                })
                .catch((err) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: err.message,
                    });
                    this.loading = false;
                })
        },
        getTotalEmissions() {
            let transformedResult = this.transformResult(this.totalEmissions);
            return transformedResult.toLocaleString('en-US') + " kg CO2e/yr";
        },
        getTableData(data) {
            // Remove all Emissions that are 0
            for(let index in data) {
                let item = data[index];
                if(item.Emissions == 0 || item.Emissions == 0.0) {
                    data.splice(index, 1);
                }
                item.Emissions = this.transformResult(item.Emissions);
            }

            // Compute Total Emissions
            this.totalEmissions = data.reduce((sum, current) => {
                return sum + current.Emissions
            }, 0)

            // Sort data by desc order of Emissiosn
            let sortedData = data.sort((a, b) => b.Emissions - a.Emissions);

            // Compute % contribution of each Emissions item
            let cumalativeArr = this.getCumulativeSum(sortedData.map(value => Number(value.Emissions / this.totalEmissions) * 100))
            let emissionsTableData = sortedData
                .map((val, index) => {
                    let emissionContribution = (Number(val.Emissions / this.totalEmissions) * 100);
                    let finalEmissionContribution = emissionContribution < 1 ?
                        emissionContribution.toFixed(2) :
                        emissionContribution >= 1 && emissionContribution <= 100 ? emissionContribution.toFixed(1) : emissionContribution.toFixed(0)

                    return {
                        ...val,
                        units: "kg CO2e/yr",
                        rank: index + 1,
                        contribution: finalEmissionContribution,
                        cumalative: cumalativeArr[index].toFixed(2)
                    }
                })
            this.items = emissionsTableData;
            this.pieChartData.labels = emissionsTableData.map(val => val._id);
            this.barChartData.labels = emissionsTableData.map(val => val._id);

            this.barChartData.datasets[0].data = emissionsTableData.map(val => val.Emissions);
            this.pieChartData.datasets[0].data = emissionsTableData.map(val => val.contribution);

            this.barChartData.datasets[0].backgroundColor = graphColors.colors.filter((_, index) => index < emissionsTableData.length);
            this.pieChartData.datasets[0].backgroundColor = graphColors.colors.filter((_, index) => index < emissionsTableData.length);

            let chart_name = "Classification Type - " + this.getOptionLabel(this.value);
            this.barChartOptions.plugins.title.text = chart_name + " (" + this.barChartData.labels.length + ")";   // TODO: <chart name> (<count of rows>)
            this.pieChartOptions.plugins.title.text = chart_name + " (" + this.pieChartData.labels.length + ")";
            if(this.items.length < 16) {
                this.barChartWrapper = "bar-chart-wrapper-sm";
                this.pieChartWrapper = "pie-chart-wrapper-sm";
            } else {
                this.barChartWrapper = "bar-chart-wrapper-lg";
                this.pieChartWrapper = "pie-chart-wrapper-lg";
            }
        },
        getTableFields(option) {
            const firstField = {
                key: "_id",
                label: "",
                class: "text-center",
                sortable: true
            };

            switch (option) {
                case "lifeCycle":
                    firstField.label = "Lifecycle Stage"
                    break;
                case "emissionCategory":
                    firstField.label = "Emission Category"
                    break;
                case "activityGroup":
                    firstField.label = "Activity Group"
                    break;
                case "activitySubGroup":
                    firstField.label = "Activity Sub-Group"
                    break;
                case "activityType":
                    firstField.label = "Activity Type"
                    break;
                case "supplyChainEntity":
                    firstField.label = "Supply Chain Entity"
                    break;
                case "product":
                    firstField.label = "Product"
                    break;
                case "department":
                    firstField.label = "Department"
                    break;
                case "orgBoundaryEntity":
                    firstField.label = "Org. Boundary Entity"
                    break;
                case "hierarical":
                    firstField.label = "Activity"
                    break;
                default:
                    break;
            }
            this.fields = [
                firstField,
                ...this.fixedFields
            ]
        },
        changeSelectedValue(value) {
            if (value) {
                if (value !== "lifeCycle") {
                    this.showHieraricalMode = false;
                    this.hieraricalData = {
                        lifeCycleStage: null,
                        emissionCategory: null,
                        activityGroup: null,
                        activitySubGroup: null,
                        activityType: null
                    }
                }
                this.getExploreProjectHighlights(value);
                this.sortBy = 'Emissions';
                this.sortDesc = true;
                this.value = value
            } else {
                this.fields = [];
                this.items = [];
                this.pieChartData.labels = [];
                this.barChartData.labels = [];
                this.pieChartData.datasets[0].data = [];
                this.barChartData.datasets[0].data = [];
            }
        },
        getCumulativeSum(arr) {
            const creds = arr.reduce((acc, val) => {
                let { sum, res } = acc;
                sum += val;
                res.push(sum);
                return { sum, res };
            }, {
                sum: 0,
                res: []
            });
            return creds.res;
        },
        handleSortChange(sortParams) {
            const { sortBy, sortDesc } = sortParams;
            let sortedArr = orderBy(this.items, [sortBy], [sortDesc ? 'desc' : 'asc'])
            let cumalativeArr = this.getCumulativeSum(sortedArr.map(value => Number(value.contribution)))
            let emissionsTableData = sortedArr
                .map((val, index) => {
                    return {
                        ...val,
                        cumalative: cumalativeArr[index].toFixed(2)
                    }
                })

            this.items = emissionsTableData;
            this.pieChartData.labels = emissionsTableData.map(val => val._id);
            this.barChartData.labels = emissionsTableData.map(val => val._id);
            this.barChartData.datasets[0].data = emissionsTableData.map(val => {
                return val.Emissions
            });
            this.pieChartData.datasets[0].data = emissionsTableData.map(val => val.contribution);
        },
        getOptionLabel(val) {
            return find(this.options, { value: val }).text
        },
        setHieraricalMode() {
            this.showHieraricalMode = true;
            this.hieraricalData = {
                lifeCycleStage: null,
                emissionCategory: null,
                activityGroup: null,
                activitySubGroup: null,
                activityType: null
            };
            this.fields = [];
            this.items = [];
            this.pieChartData.labels = [];
            this.barChartData.labels = [];
            this.pieChartData.datasets[0].data = [];
            this.barChartData.datasets[0].data = [];
            this.getLifeCycleStages()
        },
        async selectLifeCycleStage(val) {
            await this.axios
                .get("/getEfTypes?lifecycle_stage=" + val)
                .then((res) => {
                    this.hieraricalDataOptions.emissionCategory = res.data.data;
                    this.hieraricalData.lifeCycleStage = val;
                    this.hieraricalData.emissionCategory = null;
                    this.hieraricalData.activityGroup = null;
                    this.hieraricalData.activitySubGroup = null;
                    this.hieraricalData.activityType = null;
                    this.fields = [];
                    this.emissionCategoryOptions()
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },

        async selectEmissionCategory(val) {

            await this.axios
                .get("/getActivityGroups")
                .then((res) => {
                    this.hieraricalDataOptions.activityGroup = res.data.data;
                    this.hieraricalData.activityGroup = null;
                    this.hieraricalData.activitySubGroup = null;
                    this.hieraricalData.activityType = null;
                    this.hieraricalData.emissionCategory = val;
                    this.fields = [];
                    this.activityGroupOptions()
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },

        async selectActivityGroup(val) {
            await this.axios
                .get("/getEfActivitySubGroups?activity_group="+val+"&project_id="+(await this.$store.getters.projectId))
                .then((res) => {
                    this.hieraricalDataOptions.activitySubGroup = res.data.data;
                    this.hieraricalData.activitySubGroup = null;
                    this.hieraricalData.activityType = null;
                    this.hieraricalData.activityGroup = val;
                    this.fields = [];
                    this.activitySubGroupOptions()
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },

        async selectActivitySubGroup(val) {
            let reqUrl = "/getEfActivityTypes?activity_group="+this.hieraricalData.activityGroup+"&activity_sub_group="+val+"&project_id="+this.$store.getters.projectId
            await this.axios
                .get(reqUrl)
                .then((res) => {
                    this.hieraricalDataOptions.activityType = res.data.data;
                    this.hieraricalData.activityType = null;
                    this.hieraricalData.activitySubGroup = val;
                    this.fields = [];
                    this.activityTypeOptions()
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },

        selectActivityType(val) {
            this.hieraricalData.activityType = val;
            let project_id = this.$store.getters.projectId;

            let reqUrl = "/exploreEmissions?project_id=" +
                project_id + "&type=heirarical&activity_group=" +
                this.hieraricalData.activityGroup + "&activity_sub_group=" +
                this.hieraricalData.activitySubGroup + "&activity_type=" +
                this.hieraricalData.activityType + "&lcStage=" + this.hieraricalData.lifeCycleStage

            this.axios
                .get(reqUrl)
                .then((res) => {
                    this.getTableFields("hierarical")
                    this.getTableData(res.data.data)
                    this.loading = false;
                })
                .catch((err) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: err.message,
                    });
                    this.loading = false;
                })
        },

        getLifeCycleStages() {
            this.axios
                .get("/getLcStages")
                .then((res) => {
                    this.hieraricalDataOptions = { lifeCycleStage: res.data.data }
                })
                .catch((error) => {
                    console.log("geLcStages error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        emissionCategoryOptions() {
            let tempArr = this.hieraricalDataOptions.emissionCategory ?
                this.hieraricalDataOptions.emissionCategory : []
            this.hieraricalEmissionCategoryOptions = [{ value: null, text: "Select Emission Category" }, ...tempArr.map(val => ({ value: val, text: val }))]
        },
        activityGroupOptions() {
            let tempArr = this.hieraricalDataOptions.activityGroup ?
                this.hieraricalDataOptions.activityGroup : []
            this.hieraricalactivityGroupsOptions = [{ value: null, text: "Select Activity Group" }, ...tempArr.map(val => ({ value: val, text: val }))]
        },
        activitySubGroupOptions() {
            let tempArr = this.hieraricalDataOptions.activitySubGroup ?
                this.hieraricalDataOptions.activitySubGroup : []
            this.hieraricalactivitySubGroupsOptions = [{ value: null, text: "Select Activity SubGroup" }, ...tempArr.map(val => ({ value: val, text: val }))]
        },
        activityTypeOptions() {
            let tempArr = this.hieraricalDataOptions.activityType ?
                this.hieraricalDataOptions.activityType : []
            this.hieraricalactivityTypeOptions = [{ value: null, text: "Select Activity Type" }, ...tempArr.map(val => ({ value: val, text: val }))]
        },
        downloadChart(chart_type) {
            let canvas = document.getElementById(chart_type).toDataURL("image/png");
            let link = document.createElement("a");

            if (chart_type == "pie-chart") {
                link.download = this.$store.getters.projectName + " - " +
                    this.pieChartOptions.plugins.title.text + " - " + "Pie chart";
            } else if (chart_type == "bar-chart") {
                link.download = this.$store.getters.projectName + " - " +
                    this.pieChartOptions.plugins.title.text + " - " + "Bar chart";
            }

            link.href = canvas;
            link.click();
        }

    },
    computed: {
        lifeCycleOptions() {
            let tempArr = this.hieraricalDataOptions.lifeCycleStage ?
                this.hieraricalDataOptions.lifeCycleStage : []
            return [{ value: null, text: "Select lifeCycle Stage" }, ...tempArr.map(val => ({ value: val, text: val }))]
        },
    },
    created: async function () {
        if (!this.$store.getters.projectId) {
            this.$router.push("projects");
        }
    },
};

</script>

<style lang="scss">
.float {
    position: fixed;
    // bottom: 70px;
    right: 20px;
    margin: 0px;
}

.float-1 {
    bottom: 150px;
    height: 56.25px;
    width: 75px;
}

.float-2 {
    bottom: 70px;
    height: 56.25px;
    width: 75px;
}

.home {
    width: 100%;
}

.mt-30 {
    margin-top: 30px;
}

.text-center {
    text-align: center !important;
}

.body-card {
    height: calc(100vh - 67px);
    overflow-y: auto;
    padding: 50px 100px;
}

.select-label {
    font-size: 14px;
}

.table-wrapper {
    margin-top: 100px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    table {
        max-width: 600px;
        color: #000000 !important;
    }
}

.tabs-active {
    background-color: #019BB3 !important;
    color: #ffffff !important;

}

.tabs-nav {
    .nav-item {
        .nav-link {
            color: #525f7f;
        }
    }
}

.hierarical-data-label {
    margin-top: 12px;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0;
}

.download-btn-wrapper {
    display: flex;
    justify-content: flex-end;
}

.bar-chart-wrapper-sm {
    canvas {
        max-height: 500px;
    }
}
.bar-chart-wrapper-lg {
    canvas {
        max-height: auto;
    }
}

.pie-chart-wrapper-sm {
    canvas {
        max-height: 500px;
    }
}
.pie-chart-wrapper-lg {
    canvas {
        max-height: auto;
    }
}
</style>

